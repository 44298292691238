import IIconType from '../IIconType';

/**
 * IllustrationDogFace
 *
 * @param {number} size - The size of the icon
 * @returns {React.ReactNode} - The SVG element
 */
const IllustrationDogFacePurple: React.FC<IIconType> = ({
  size,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="illustration-dog-face-purple"
    >
      <g clipPath="url(#clip0_7345_390)">
        <path
          d="M16.5839 3.90425C16.6939 3.85382 19.9884 2.36085 20.4924 5.0922C20.8063 6.79256 21.6936 9.23428 22.275 11.0924C22.6434 12.2698 22.8888 13.2129 22.788 13.5851C22.5852 14.3332 20.582 13.8998 19.7087 13.6725C19.6807 13.6652 19.6537 13.6579 19.6281 13.6512C19.4153 13.5945 19.2295 13.4653 19.1017 13.286C18.9493 13.0721 18.7233 12.7391 18.4663 12.3084C17.4461 10.5997 15.9358 7.3535 16.5812 3.90559L16.5839 3.90425Z"
          fill="#DFCCF3"
        />
        <path
          d="M18.4663 12.3084L18.4472 12.3197C18.8029 15.2482 18.2144 15.2981 17.7581 15.9146L17.7473 15.9065C17.1969 15.4184 16.5267 14.9772 15.8474 14.6948C15.4189 14.5168 14.968 14.3817 14.5764 14.1328C14.2226 13.9082 13.937 13.6002 13.6975 13.259C11.5934 10.2628 13.2922 6.60565 14.1009 3.31659C14.9073 3.42527 15.7436 3.61162 16.5839 3.90423L16.5812 3.90558C15.9359 7.35349 17.4461 10.5997 18.4663 12.3084ZM16.0483 10.0409C16.0483 9.4972 15.6077 9.05654 15.064 9.05654C14.5203 9.05654 14.0796 9.4972 14.0796 10.0409C14.0796 10.5846 14.5203 11.0252 15.064 11.0252C15.6077 11.0252 16.0483 10.5846 16.0483 10.0409Z"
          fill="#DFCCF3"
        />
        <path
          d="M12.029 17.7274C13.235 17.7274 14.2126 17.0362 14.2126 16.1835C14.2126 15.3307 13.235 15.7843 12.029 15.7843C10.8229 15.7843 9.84559 15.3307 9.84559 16.1835C9.84559 17.0362 10.8232 17.7274 12.029 17.7274ZM12.0031 19.3447C11.7328 19.5232 9.47828 20.9243 7.58025 19.3045H7.57971C7.1741 18.6808 6.82837 17.8315 6.55653 16.6751C6.4767 16.3364 6.3637 16.1158 6.23938 15.9416C6.7898 15.4537 7.42167 15.0581 8.10101 14.7757C8.52954 14.5977 8.98072 14.4626 9.3723 14.2137C9.72612 13.9891 10.0114 13.6811 10.2509 13.3399C12.3558 10.3424 10.6546 6.68333 9.84667 3.39318L9.84209 3.36136C11.0033 3.19119 12.4923 3.0995 14.1009 3.31659C13.2922 6.60566 11.5934 10.2628 13.6975 13.259C13.937 13.6002 14.2226 13.9082 14.5764 14.1328C14.968 14.3817 15.4189 14.5168 15.8474 14.6948C16.5267 14.9772 17.1969 15.4184 17.7473 15.9065L17.7581 15.9146C17.6262 16.0929 17.5054 16.3186 17.4216 16.6751C17.1422 17.8633 16.7848 18.7271 16.3644 19.3555C14.4796 20.8943 12.2706 19.5214 12.0031 19.3447ZM12.029 19.3272L12.03 19.3264L12.0295 18.5332L12.029 19.3272Z"
          fill="white"
        />
        <path
          d="M15.0639 9.05652C15.6076 9.05652 16.0483 9.49718 16.0483 10.0409C16.0483 10.5845 15.6076 11.0252 15.0639 11.0252C14.5203 11.0252 14.0796 10.5845 14.0796 10.0409C14.0796 9.49718 14.5203 9.05652 15.0639 9.05652ZM15.6723 9.64308C15.6977 9.57944 15.63 9.49206 15.5205 9.44621C15.4957 9.4276 15.4679 9.41115 15.4377 9.39659C15.2843 9.32324 15.1257 9.33483 15.0839 9.42275C15.0421 9.5104 15.1893 9.52172 15.3428 9.59508C15.4962 9.66843 15.5979 9.77577 15.6397 9.68812C15.6429 9.68165 15.6451 9.6749 15.6467 9.66789C15.6578 9.66546 15.6667 9.65791 15.6723 9.64308Z"
          fill="#5F249F"
        />
        <path
          d="M15.5205 9.44623C15.63 9.49207 15.6977 9.57945 15.6723 9.6431C15.6667 9.65793 15.6578 9.66548 15.6467 9.66791C15.6634 9.59995 15.6122 9.51284 15.5205 9.44623Z"
          fill="#5F249F"
        />
        <path
          d="M15.5205 9.44622C15.6122 9.51283 15.6634 9.59994 15.6467 9.6679C15.609 9.67653 15.5423 9.62556 15.4539 9.59023C15.3398 9.54493 15.2333 9.54385 15.2592 9.47858C15.2851 9.41332 15.3986 9.39741 15.5127 9.44272C15.5154 9.44379 15.5181 9.44487 15.5205 9.44622Z"
          fill="white"
        />
        <path
          d="M15.6467 9.66791C15.6451 9.67493 15.6429 9.68167 15.6397 9.68814C15.5979 9.77579 15.4962 9.66845 15.3428 9.5951C15.1893 9.52175 15.0421 9.51042 15.0839 9.42277C15.1257 9.33485 15.2842 9.32326 15.4377 9.39661C15.4679 9.41118 15.4957 9.42763 15.5205 9.44623C15.5181 9.44489 15.5154 9.44381 15.5127 9.44273C15.3986 9.39742 15.285 9.41333 15.2592 9.4786C15.2333 9.54386 15.3398 9.54494 15.4539 9.59025C15.5423 9.62557 15.6089 9.67654 15.6467 9.66791Z"
          fill="white"
        />
        <path
          d="M16.3644 19.3555C15.2619 21.0038 13.7261 21.03 12.03 21.0305H11.9478C10.2345 21.03 8.68488 21.0033 7.57971 19.3048H7.58025C9.47829 20.9243 11.7328 19.5233 12.0031 19.3447C12.2706 19.5214 14.4796 20.8943 16.3644 19.3555Z"
          fill="#DFCCF3"
        />
        <path
          d="M12.0289 15.7843C13.235 15.7843 14.2126 15.3307 14.2126 16.1835C14.2126 17.0362 13.235 17.7274 12.0289 17.7274C10.8229 17.7274 9.84558 17.0362 9.84558 16.1835C9.84558 15.3307 10.8232 15.7843 12.0289 15.7843Z"
          fill="#5F249F"
        />
        <path
          d="M9.84659 3.39315C10.6546 6.68329 12.3557 10.3424 10.2509 13.3399C10.0114 13.6811 9.72605 13.989 9.37222 14.2137C8.98064 14.4626 8.52946 14.5977 8.10093 14.7757C7.4216 15.0581 6.78973 15.4537 6.2393 15.9415C5.78057 15.2989 5.16974 15.2905 5.53057 12.3197L5.52761 12.3178C6.54647 10.6139 8.06102 7.36802 7.42079 3.9185L7.42807 3.91715C7.42807 3.91715 8.37304 3.57654 9.84201 3.36133L9.84659 3.39315ZM10.1961 10.0409C10.1961 9.49718 9.75544 9.05651 9.21176 9.05651C8.66808 9.05651 8.22741 9.49718 8.22741 10.0409C8.22741 10.5845 8.66808 11.0252 9.21176 11.0252C9.75544 11.0252 10.1961 10.5845 10.1961 10.0409Z"
          fill="#DFCCF3"
        />
        <path
          d="M9.21176 9.05652C9.75545 9.05652 10.1961 9.49718 10.1961 10.0409C10.1961 10.5845 9.75545 11.0252 9.21176 11.0252C8.66808 11.0252 8.22742 10.5845 8.22742 10.0409C8.22742 9.49718 8.66808 9.05652 9.21176 9.05652ZM9.77109 9.70861C9.82017 9.62501 9.74088 9.4872 9.59417 9.40117C9.44747 9.31515 9.28862 9.31353 9.23954 9.3974C9.19046 9.48127 9.33636 9.505 9.48306 9.59103C9.62977 9.67706 9.722 9.79249 9.77109 9.70861Z"
          fill="#5F249F"
        />
        <path
          d="M9.59419 9.40119C9.7409 9.48722 9.82018 9.62503 9.7711 9.70863C9.72202 9.7925 9.62979 9.67707 9.48308 9.59105C9.33637 9.50502 9.19047 9.48128 9.23955 9.39741C9.28864 9.31354 9.44748 9.31516 9.59419 9.40119Z"
          fill="white"
        />
        <path
          d="M7.42082 3.91852C8.06105 7.36805 6.54651 10.614 5.52764 12.3178C5.27279 12.7439 5.04922 13.0738 4.89793 13.286C4.7701 13.4653 4.58429 13.5945 4.37151 13.6511C3.54789 13.8707 1.42063 14.3572 1.21135 13.5851C0.951376 12.6255 2.9945 7.86993 3.50717 5.09219C4.01984 2.31444 7.41839 3.90558 7.41839 3.90558L7.42082 3.91852Z"
          fill="#DFCCF3"
        />
        <path
          d="M11.9474 21.2193C10.3013 21.2188 8.59932 21.2182 7.42134 19.4078C6.98229 18.7323 6.63925 17.8526 6.37254 16.7183C6.28894 16.3639 6.16866 16.1679 6.08559 16.0511C6.01575 15.9532 5.94185 15.8712 5.86337 15.7841C5.65383 15.5516 5.43727 15.3111 5.3205 14.7935C5.19591 14.2426 5.20319 13.4492 5.34315 12.2968L5.71801 12.3424C5.4273 14.7356 5.77546 15.122 6.14411 15.5314C6.2261 15.6223 6.31078 15.7164 6.39303 15.8318C6.55484 16.0584 6.66514 16.3127 6.74039 16.6317C6.99686 17.7229 7.32317 18.5635 7.73822 19.2018C8.21825 19.9397 8.8067 20.3914 9.5896 20.6233C10.3253 20.8412 11.1503 20.8415 11.9483 20.8417V21.2193H11.9474Z"
          fill="#5F249F"
        />
        <path
          d="M12.03 21.2193H11.9478V20.8417H12.03C13.6314 20.8412 15.1438 20.8407 16.2074 19.2506C16.6376 18.6077 16.9747 17.7512 17.2376 16.632C17.3155 16.3005 17.4329 16.0368 17.6063 15.8024C17.6818 15.7002 17.7589 15.6147 17.8336 15.5317C18.2023 15.1223 18.5504 14.7356 18.2597 12.3424L18.6346 12.2968C18.7745 13.4492 18.7818 14.2426 18.6572 14.7935C18.5404 15.3111 18.3236 15.5516 18.1143 15.7841C18.0426 15.8639 17.9746 15.9392 17.9099 16.0268C17.8131 16.1576 17.6909 16.3547 17.6055 16.7183C17.3317 17.8822 16.9771 18.7792 16.5213 19.4604C15.3458 21.2182 13.6602 21.2188 12.03 21.2193Z"
          fill="#5F249F"
        />
        <path
          d="M12.0289 17.7409C10.8175 17.7409 9.83203 17.0422 9.83203 16.1835C9.83203 15.9961 9.87869 15.8634 9.97469 15.7779C10.1751 15.5991 10.5691 15.6439 11.0683 15.7008C11.3571 15.7337 11.6845 15.7709 12.0289 15.7709C12.3733 15.7709 12.7009 15.7337 12.9898 15.7008C13.4887 15.6439 13.883 15.5991 14.0833 15.7779C14.1793 15.8637 14.226 15.9963 14.226 16.1835C14.226 17.0422 13.2403 17.7409 12.0289 17.7409ZM10.4488 15.6773C10.2506 15.6773 10.0939 15.7075 9.99276 15.7978C9.90296 15.8782 9.859 16.0042 9.859 16.1835C9.859 17.0273 10.8323 17.714 12.0289 17.714C13.2255 17.714 14.199 17.0273 14.199 16.1835C14.199 16.0044 14.1553 15.8782 14.0653 15.7978C13.8741 15.6271 13.4852 15.6714 12.9927 15.7275C12.7031 15.7604 12.3749 15.7978 12.0289 15.7978C11.6829 15.7978 11.3547 15.7604 11.065 15.7275C10.8334 15.701 10.6246 15.6773 10.4488 15.6773Z"
          fill="#5F249F"
        />
        <path
          d="M2.21273 14.1935C1.52746 14.1935 1.12995 14.006 1.02908 13.6344C0.880219 13.0845 1.31576 11.7078 2.03932 9.50718C2.53203 8.00773 3.09082 6.30846 3.32167 5.05766C3.45974 4.3101 3.80926 3.78718 4.36049 3.50347C4.88934 3.23136 5.58081 3.18875 6.41602 3.37699C7.0247 3.51426 7.47965 3.72542 7.4988 3.73432L7.58645 3.77532L7.60694 3.88373C8.26308 7.41955 6.68759 10.7461 5.69003 12.4144C5.42655 12.855 5.19759 13.1911 5.05196 13.3952C4.89878 13.6099 4.67467 13.7655 4.42036 13.8332C4.025 13.9386 3.25991 14.1234 2.57626 14.1778C2.44735 14.1881 2.32626 14.1932 2.213 14.1932L2.21273 14.1935ZM5.46646 3.64047C5.13961 3.64047 4.81464 3.69441 4.53228 3.83977C4.0908 4.06711 3.80845 4.49996 3.69275 5.12643C3.45732 6.40231 2.89449 8.11453 2.398 9.6253C1.84191 11.3176 1.26668 13.0673 1.3937 13.5357C1.50157 13.9338 2.67874 13.9071 4.323 13.4688C4.49263 13.4238 4.64204 13.32 4.74425 13.1765C4.88583 12.9777 5.10859 12.6509 5.3656 12.221C6.32756 10.6121 7.84319 7.4209 7.25069 4.03853C6.99611 3.93227 6.22724 3.64074 5.46646 3.64074V3.64047Z"
          fill="#5F249F"
        />
        <path
          d="M21.7875 14.1937C21.6928 14.1937 21.5925 14.1902 21.4868 14.1829C20.8525 14.1398 20.1438 13.9809 19.661 13.8553C19.6438 13.8507 19.6268 13.8464 19.6103 13.8418L19.5799 13.8337C19.325 13.766 19.1009 13.6104 18.9477 13.3955C18.7997 13.1875 18.5677 12.8472 18.304 12.4049C17.3072 10.7353 15.7336 7.40659 16.3954 3.8705L16.4127 3.77826L16.5049 3.73215C16.5567 3.70842 17.0279 3.49672 17.6298 3.36673C18.4483 3.18981 19.1265 3.23701 19.6459 3.50696C20.1934 3.79148 20.5405 4.31305 20.6778 5.05765C20.9086 6.30844 21.4674 8.00799 21.9601 9.50743C22.1376 10.0471 22.305 10.5568 22.455 11.0357C22.925 12.5384 23.0696 13.2668 22.9701 13.6341C22.8692 14.0058 22.472 14.1935 21.7875 14.1935V14.1937ZM16.749 4.03824C16.157 7.41576 17.6681 10.6034 18.6284 12.2115C18.8857 12.6428 19.1114 12.9742 19.2554 13.1765C19.3576 13.32 19.5073 13.4235 19.6767 13.4688L19.7562 13.4899C21.3552 13.906 22.5003 13.9246 22.6057 13.5357C22.7152 13.1317 22.2646 11.6911 22.0947 11.1487C21.9455 10.6725 21.7786 10.1641 21.6017 9.62555C21.1052 8.11478 20.5424 6.40229 20.3067 5.12641C20.1915 4.50237 19.9108 4.07033 19.472 3.84218C18.5303 3.35297 17.1147 3.88506 16.749 4.03824Z"
          fill="#5F249F"
        />
        <path
          d="M9.21182 11.0387C8.66166 11.0387 8.21399 10.591 8.21399 10.0409C8.21399 9.4907 8.66166 9.04303 9.21182 9.04303C9.76197 9.04303 10.2096 9.4907 10.2096 10.0409C10.2096 10.591 9.76197 11.0387 9.21182 11.0387ZM9.21182 9.07C8.6765 9.07 8.24096 9.50554 8.24096 10.0409C8.24096 10.5762 8.6765 11.0117 9.21182 11.0117C9.74714 11.0117 10.1827 10.5762 10.1827 10.0409C10.1827 9.50554 9.74714 9.07 9.21182 9.07Z"
          fill="#5F249F"
        />
        <path
          d="M15.064 11.0387C14.5138 11.0387 14.0662 10.591 14.0662 10.0409C14.0662 9.4907 14.5138 9.04303 15.064 9.04303C15.6141 9.04303 16.0618 9.4907 16.0618 10.0409C16.0618 10.591 15.6141 11.0387 15.064 11.0387ZM15.064 9.07C14.5287 9.07 14.0931 9.50554 14.0931 10.0409C14.0931 10.5762 14.5287 11.0117 15.064 11.0117C15.5993 11.0117 16.0349 10.5762 16.0349 10.0409C16.0349 9.50554 15.5993 9.07 15.064 9.07Z"
          fill="#5F249F"
        />
        <path
          d="M7.49199 4.0949L7.36389 3.73972C7.40327 3.72543 8.34365 3.39021 9.81451 3.17473C11.2924 2.95818 12.743 2.94308 14.1259 3.1297C14.9995 3.24755 15.8473 3.44819 16.6459 3.72624L16.6505 3.72786C16.6529 3.72866 16.6553 3.72974 16.658 3.73082L16.5561 3.98486L16.5876 3.90612L16.5264 4.08465C16.5242 4.08384 16.5221 4.0833 16.5199 4.08249C15.7459 3.81308 14.9234 3.61863 14.0758 3.50429C12.7276 3.32225 11.3123 3.33735 9.86952 3.54879C8.43912 3.75833 7.50143 4.0922 7.49226 4.09544L7.49199 4.0949Z"
          fill="#5F249F"
        />
        <path
          d="M6.36448 16.0829L6.11206 15.8021L6.11422 15.8003C6.68271 15.2965 7.32672 14.893 8.0287 14.6012C8.14871 14.5514 8.27115 14.5047 8.38954 14.4594C8.70507 14.3388 9.00307 14.2248 9.27141 14.0543C9.5721 13.8634 9.84206 13.5942 10.0969 13.2315C11.7735 10.8437 10.9521 7.99453 10.1579 5.23917C9.98634 4.64478 9.80916 4.02991 9.6638 3.43822L10.0306 3.34814C10.174 3.93255 10.3501 4.54365 10.5206 5.13453C10.9221 6.52772 11.3377 7.96837 11.4329 9.37126C11.5419 10.9756 11.2058 12.3095 10.406 13.4483C10.1223 13.8526 9.81752 14.1549 9.47394 14.3731C9.17378 14.564 8.84369 14.6902 8.52465 14.8121C8.40869 14.8564 8.28895 14.9022 8.17406 14.9499C7.51091 15.2256 6.90223 15.6066 6.36529 16.0826L6.36448 16.0829Z"
          fill="#5F249F"
        />
        <path
          d="M17.6219 16.0478C17.0596 15.5492 16.4034 15.1306 15.7748 14.8693C15.6599 14.8215 15.5402 14.7757 15.4245 14.7315C15.1054 14.6093 14.7754 14.4831 14.4749 14.2922C14.1314 14.074 13.8263 13.7717 13.5429 13.3674C12.743 12.2283 12.407 10.8944 12.5159 9.28979C12.6111 7.88689 13.0267 6.44597 13.4286 5.05278C13.5982 4.46514 13.7732 3.85728 13.9164 3.27584L13.9191 3.26532C13.9186 3.26721 13.918 3.26937 13.9178 3.27125L14.01 3.29391L14.2872 3.34838L14.2843 3.36133C14.1392 3.9514 13.9623 4.56439 13.7915 5.15715C12.9968 7.91278 12.1751 10.7623 13.8522 13.1503C14.1068 13.513 14.3768 13.7822 14.6777 13.9731C14.9461 14.1438 15.2441 14.2576 15.5596 14.3785C15.678 14.4238 15.8002 14.4704 15.9199 14.5203C16.5863 14.7973 17.2799 15.2393 17.8727 15.7652L17.6221 16.0475L17.6219 16.0478Z"
          fill="#5F249F"
        />
        <path
          d="M9.6447 20.2381C8.94946 20.2381 8.18868 20.0367 7.4751 19.4277L7.68518 19.1815C9.41305 20.6562 11.4715 19.489 11.868 19.2392L11.8669 17.7277H12.1905L12.1916 19.3889L12.1352 19.4509L12.1191 19.4615C12.1145 19.4647 12.1056 19.4706 12.0926 19.4795C11.9235 19.5912 10.8898 20.2381 9.6447 20.2384V20.2381Z"
          fill="#5F249F"
        />
        <path
          d="M14.3616 20.2376C13.1154 20.2376 12.0809 19.5901 11.9137 19.4795C11.893 19.4658 11.8824 19.4582 11.8824 19.4582L12.0691 19.1939C12.0691 19.1939 12.078 19.2002 12.0925 19.2099C12.3293 19.3663 14.4631 20.6985 16.2616 19.2298C16.2818 19.2136 16.3009 19.1977 16.3201 19.1813L16.5304 19.4272C16.5086 19.4458 16.4868 19.4642 16.4649 19.4814C15.771 20.0478 15.0351 20.2374 14.3611 20.2374L14.3616 20.2376Z"
          fill="#5F249F"
        />
        <path
          d="M12.1913 17.7404H11.8677V19.339H12.1913V17.7404Z"
          fill="#5F249F"
        />
        <path
          d="M9.72695 9.75124C9.69 9.75124 9.64685 9.72023 9.58941 9.6787C9.55597 9.6547 9.51821 9.62719 9.47614 9.60265C9.4338 9.57784 9.39146 9.55815 9.35397 9.54089C9.28251 9.50772 9.231 9.48399 9.21886 9.44515C9.21347 9.42816 9.2167 9.41009 9.22776 9.39067C9.24502 9.36101 9.27523 9.33997 9.31487 9.32946C9.39335 9.30896 9.50284 9.33188 9.60073 9.3896C9.67382 9.43248 9.73288 9.48911 9.7674 9.54925C9.80327 9.61182 9.80866 9.67088 9.7825 9.71564C9.77117 9.73479 9.75661 9.74639 9.73935 9.75016C9.73504 9.75097 9.73099 9.75151 9.72668 9.75151L9.72695 9.75124ZM9.37717 9.34887C9.35748 9.34887 9.33887 9.35103 9.32188 9.35535C9.28925 9.36398 9.26471 9.3807 9.25122 9.40389C9.24394 9.41656 9.24179 9.42708 9.24475 9.43652C9.25338 9.46403 9.30543 9.48803 9.36557 9.51608C9.4036 9.53361 9.44674 9.55356 9.48989 9.57918C9.53304 9.60453 9.57134 9.63231 9.60532 9.65658C9.65926 9.69569 9.70591 9.72913 9.73423 9.7232C9.74394 9.72104 9.7523 9.71403 9.75958 9.70135C9.78088 9.66521 9.77549 9.61586 9.74448 9.56219C9.71211 9.5061 9.65656 9.45297 9.58752 9.41252C9.51713 9.37126 9.44135 9.3486 9.37744 9.3486L9.37717 9.34887Z"
          fill="#5F249F"
        />
        <path
          d="M15.5934 9.73454C15.5575 9.73454 15.5136 9.70811 15.4561 9.67359C15.4208 9.65228 15.3809 9.62828 15.3367 9.60725C15.2924 9.58621 15.2485 9.57003 15.2096 9.55601C15.1358 9.52904 15.0824 9.50962 15.067 9.47186C15.0602 9.45541 15.0619 9.43708 15.0713 9.41685C15.0861 9.38584 15.1142 9.36237 15.153 9.34862C15.2293 9.32165 15.3404 9.3354 15.4432 9.38449C15.475 9.39986 15.5036 9.41685 15.5281 9.43546C15.6255 9.50611 15.677 9.59862 15.6595 9.67116C15.6573 9.68006 15.6549 9.68761 15.6514 9.69435C15.6419 9.71431 15.6285 9.72699 15.6115 9.73211C15.6055 9.734 15.5993 9.73481 15.5931 9.73481L15.5934 9.73454ZM15.2417 9.36183C15.2126 9.36183 15.1856 9.36588 15.1624 9.37397C15.1306 9.3853 15.1077 9.40417 15.0961 9.42845C15.0899 9.44166 15.0886 9.45245 15.0923 9.46162C15.1031 9.48832 15.1571 9.50773 15.2194 9.53066C15.2587 9.54495 15.3032 9.56113 15.3485 9.58297C15.3936 9.60455 15.4343 9.62909 15.4702 9.6504C15.5273 9.68465 15.5764 9.71431 15.6042 9.70595C15.6136 9.70298 15.6215 9.69543 15.6277 9.68222C15.6304 9.67709 15.6322 9.67143 15.6336 9.66469C15.6487 9.60293 15.6012 9.52149 15.5125 9.45703C15.489 9.4395 15.4618 9.42305 15.4319 9.40876C15.3674 9.37801 15.3003 9.36183 15.2417 9.36183Z"
          fill="#5F249F"
        />
      </g>
      <defs>
        <clipPath id="clip0_7345_390">
          <rect
            width="22"
            height="18.2193"
            fill="white"
            transform="translate(1 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationDogFacePurple;
