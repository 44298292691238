import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineFacebookStroke: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-social-media-facebook-stroke"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2002 13.125L16.6924 9.89062H13.5635V7.78125C13.5635 6.86719 13.9854 6.02344 15.3916 6.02344H16.833V3.24609C16.833 3.24609 15.5322 3 14.3018 3C11.7354 3 10.0479 4.58203 10.0479 7.39453V9.89062H7.16504V13.125H10.0479V21H13.5635V13.125H16.2002Z"
        stroke={color}
        strokeWidth="1.25"
      />
    </svg>
  );
};

export default IconOutlineFacebookStroke;
