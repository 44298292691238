import datafile from '@/lib/utils/featureFlags/optimizely/datafile.json';
import { createInstance } from '@optimizely/optimizely-sdk/dist/optimizely.lite.min.js';
import { NextRequest, NextResponse } from 'next/server';
import { v4 as uuidv4 } from 'uuid';
import AbstractFeatureFlagMiddleware from '../abstractFeatureFlagMiddleware';
import { getFeatureFlagOverride } from './featureFlagOverride';

/**
 * The variable name to look for on the feature flags to determine if the
 * analytics feature is enabled
 *
 * @constant {string} analyticsFeatureVariable
 */
export const analyticsFeatureVariable = 'to_analytics';

/**
 * The key to use in the cookies to store the analytics feature flag
 *
 * @constant {string} analyticsFeatureKey
 */
export const analyticsFeatureKey = 'analytics_feature';

/**
 * OptimizelyMiddleware This is used to get the feature flags from Optimizely
 * and set them in the cookies.
 *
 * @augments AbstractFeatureFlagMiddleware
 * @class
 */
export default class OptimizelyMiddleware extends AbstractFeatureFlagMiddleware {
  /**
   * @param {NextRequest} req - The incoming request
   * @returns {NextResponse} - The outgoing response
   */
  processFeatureFlagMiddleware(req: NextRequest): NextResponse {
    // Get the feature flag override
    const overrideFlag = getFeatureFlagOverride(req);
    // Create an array to store the feature flags
    const decisionQuery: string[] = [];

    if (Object.keys(datafile).length === 0) {
      const response = NextResponse.next();
      response.cookies.set(this.featureFlagDecisionKey, JSON.stringify({}), {
        maxAge: this.featureFlagExpiration,
      });
      response.cookies.set(analyticsFeatureKey, JSON.stringify({}));
      if (overrideFlag) {
        // Add the override flag to the decision query
        decisionQuery.push(overrideFlag);
        // Set the feature flags in the cookies
        response.cookies.set(
          this.featureFlagDecisionKey,
          JSON.stringify({ decisions: decisionQuery.join(',') }),
          {
            maxAge: this.featureFlagExpiration,
          }
        );
      }
      return response;
    }

    const optimizelyUserKeyCookie = req.cookies.get(
      this.featureFlagUserKey
    )?.value;
    // Get the user id from the cookies
    const userId = optimizelyUserKeyCookie || uuidv4();

    // Create a new instance of the Optimizely SDK
    const optimizely = createInstance({
      datafile: datafile,
      clientEngine: 'javascript-sdk/vercel-edge',
    });

    // Create Optimizely User Context
    const userContext = optimizely?.createUserContext(userId.toString());

    // Get all decisions for the user
    const decisions = userContext?.decideAll();

    if (overrideFlag) {
      // If the override flag is set, add it to the decision query which will be pushed up to the cookies
      decisionQuery.push(overrideFlag);
    }

    if (decisions && Object.keys(decisions).length > 0) {
      Object.keys(decisions).map((key) => {
        if (decisions[key].enabled) {
          decisionQuery.push(key);
        }
      });
    }

    const response = NextResponse.next();

    if (!optimizelyUserKeyCookie) {
      response.cookies.set(this.featureFlagUserKey, userId, {
        maxAge: this.featureFlagExpiration,
      });
    }
    response.cookies.set(analyticsFeatureKey, JSON.stringify({}));
    if (decisionQuery.length > 0) {
      // Set the feature flags in the cookies
      response.cookies.set(
        this.featureFlagDecisionKey,
        JSON.stringify({ decisions: decisionQuery.join(',') }),
        {
          maxAge: this.featureFlagExpiration,
          sameSite: 'none',
          secure: true,
        }
      );
      let analytic: string | null = null;

      // Set the active analytic feature flag in the cookies
      for (const decision of decisionQuery) {
        if (decisions && Object.keys(decisions).includes(decision)) {
          const analyticsFeature = userContext?.decide(decision);
          if (analyticsFeature?.variables[analyticsFeatureVariable]) {
            analytic = decision;
          }
        }
      }
      if (analytic) {
        response.cookies.set(
          analyticsFeatureKey,
          JSON.stringify({ analytic: analytic }),
          {
            maxAge: this.featureFlagExpiration,
          }
        );
      }
    } else {
      response.cookies.set(this.featureFlagDecisionKey, JSON.stringify({}), {
        maxAge: this.featureFlagExpiration,
      });
    }

    return response;
  }
}
