import { petPhotoPlaceHolderImage } from '@/lib/constants/constants/photos';

/**
 * Take in photo object key or photo path and return the full url. Returns a
 * placeholder image if no photo path is provided.
 *
 * @param {string | undefined} [photoPath] - The photo path
 * @returns {string} - The complete url
 */
const generateImageUrl = (photoPath?: string): string => {
  const baseCDN = process.env.NEXT_PUBLIC_CDN_BASE_URL;
  if (!photoPath) {
    return `${baseCDN}${petPhotoPlaceHolderImage}`;
  }
  if (photoPath.startsWith('http')) {
    return photoPath;
  }
  if (photoPath.startsWith('/')) {
    return `${baseCDN}${photoPath}`;
  }
  return `${baseCDN}/${photoPath}`;
};

export default generateImageUrl;
