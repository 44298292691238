import IIconType from '../IIconType';

/**
 * IllustrationCatFace
 *
 * @param {number} size - The size of the icon
 * @returns {React.ReactNode} - The SVG element
 */
const IllustrationCatFacePurple: React.FC<IIconType> = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="illustration-cat-face-purple"
    >
      <g clipPath="url(#clip0_7345_389)">
        <path
          d="M4.79879 16.2737C4.60168 15.9425 4.44053 15.6293 4.31705 15.3488C4.1593 14.9908 4.06527 14.5752 4.01486 14.1425C3.96162 13.6864 3.95737 13.2107 3.97861 12.7623C5.46548 10.0003 7.41767 9.89084 8.11352 8.92512C8.80966 7.95941 8.11352 6.31929 8.11352 6.31929C9.10675 6.06303 10.9966 5.69647 13.1904 5.87719L13.1862 5.92878L11.4433 10.3586C11.2569 10.8329 11.2583 11.3608 11.4475 11.834C11.9329 13.0485 13.1499 13.8347 14.485 13.8347C14.5408 13.8347 14.5968 13.8332 14.6532 13.8304C14.7741 13.8244 14.8934 13.7996 15.0069 13.758L19.6513 12.0534C19.7199 12.6697 19.7609 13.4213 19.6884 14.1334C19.6411 14.5994 19.5454 15.0486 19.3763 15.4323C19.2718 15.67 19.1365 15.9303 18.9719 16.2036C18.7114 16.6377 18.3777 17.1055 17.9784 17.5704C16.5881 19.19 14.4017 20.7777 11.7239 20.8005C9.143 20.7785 7.12426 19.2669 5.80194 17.6853C5.40289 17.2081 5.06728 16.7244 4.79879 16.2737ZM11.863 16.4807C11.9978 16.4713 12.13 16.416 12.2348 16.3142L12.7486 15.8148C13.1323 15.4417 12.8698 14.7875 12.3359 14.7875H11.3252C10.7958 14.7875 10.531 15.4317 10.9057 15.808L11.4028 16.3074C11.5288 16.4342 11.6979 16.4921 11.863 16.4807ZM10.6936 11.6455C10.6936 11.029 10.1968 10.529 9.58396 10.529C8.97109 10.529 8.47433 11.029 8.47433 11.6455C8.47433 12.2621 8.97109 12.7623 9.58396 12.7623C10.1968 12.7623 10.6936 12.2624 10.6936 11.6455Z"
          fill="#F5EFFB"
        />
        <path
          d="M19.6514 12.0534L15.0069 13.758C14.8934 13.7996 14.7741 13.8244 14.6532 13.8304C14.5969 13.8332 14.5408 13.8347 14.485 13.8347C13.1499 13.8347 11.933 13.0485 11.4475 11.834C11.2583 11.3608 11.2569 10.8329 11.4433 10.3586L13.1862 5.92878L13.1904 5.87719C14.2009 5.96013 15.2757 6.15938 16.357 6.53905H16.4165C16.4165 6.53905 17.359 4.67261 18.5154 3.40304C18.6627 3.24113 18.9136 3.24313 19.0575 3.40845C19.8819 4.35507 20.7488 8.51153 19.5058 10.6034C19.4625 10.6761 19.4472 10.7631 19.4644 10.8466C19.5072 11.0567 19.5902 11.5007 19.6514 12.0534ZM15.1865 11.6455C15.1865 11.029 14.6897 10.529 14.0772 10.529C13.4646 10.529 12.9675 11.029 12.9675 11.6455C12.9675 12.2621 13.4643 12.7623 14.0772 12.7623C14.69 12.7623 15.1865 12.2624 15.1865 11.6455Z"
          fill="#DFCCF3"
        />
        <path
          d="M14.0772 10.5291C14.6897 10.5291 15.1865 11.029 15.1865 11.6456C15.1865 12.2621 14.6897 12.7623 14.0772 12.7623C13.4646 12.7623 12.9675 12.2624 12.9675 11.6456C12.9675 11.0287 13.4643 10.5291 14.0772 10.5291ZM14.7628 11.1943C14.7914 11.1219 14.7155 11.0236 14.5923 10.9711C14.5643 10.9503 14.5326 10.9312 14.4983 10.9147C14.3253 10.8315 14.1468 10.8449 14.0995 10.9444C14.0522 11.0438 14.2185 11.0567 14.3915 11.1399C14.5646 11.2231 14.679 11.3451 14.726 11.2456C14.7297 11.2382 14.7322 11.2305 14.7342 11.2226C14.7467 11.2197 14.7563 11.2112 14.7628 11.1943Z"
          fill="#5F249F"
        />
        <path
          d="M14.5923 10.9711C14.7155 11.0236 14.7914 11.1219 14.7628 11.1943C14.7563 11.2111 14.7466 11.2197 14.7342 11.2225C14.7526 11.1453 14.6954 11.047 14.5923 10.9711Z"
          fill="#5F249F"
        />
        <path
          d="M14.5923 10.9711C14.6954 11.047 14.7526 11.1453 14.7342 11.2225C14.6917 11.2325 14.6161 11.1744 14.5167 11.1345C14.3881 11.0829 14.2678 11.0817 14.2972 11.0076C14.3264 10.9338 14.4544 10.9156 14.583 10.9672C14.5861 10.9683 14.5892 10.9697 14.5923 10.9711Z"
          fill="white"
        />
        <path
          d="M14.7342 11.2225C14.7322 11.2305 14.7296 11.2382 14.7259 11.2456C14.6789 11.3451 14.5645 11.2231 14.3915 11.1399C14.2184 11.0566 14.0522 11.0438 14.0995 10.9443C14.1468 10.8449 14.3252 10.8315 14.4982 10.9147C14.5325 10.9312 14.5642 10.9503 14.5923 10.9711C14.5891 10.9697 14.586 10.9683 14.5829 10.9671C14.4543 10.9155 14.3263 10.9338 14.2972 11.0076C14.2677 11.0817 14.3881 11.0829 14.5166 11.1345C14.6161 11.1744 14.6917 11.2325 14.7342 11.2225Z"
          fill="white"
        />
        <path
          d="M11.8629 16.4807C11.6978 16.4921 11.5287 16.4342 11.4027 16.3074L10.9057 15.808C10.531 15.4317 10.7958 14.7875 11.3251 14.7875H12.3359C12.8697 14.7875 13.1323 15.4417 12.7485 15.8148L12.2348 16.3142C12.13 16.416 11.9977 16.4713 11.8629 16.4807Z"
          fill="#5F249F"
        />
        <path
          d="M9.58387 10.5291C10.1967 10.5291 10.6935 11.029 10.6935 11.6456C10.6935 12.2621 10.1967 12.7623 9.58387 12.7623C8.971 12.7623 8.47424 12.2624 8.47424 11.6456C8.47424 11.0287 8.971 10.5291 9.58387 10.5291ZM10.2143 11.269C10.2698 11.1738 10.1806 11.0176 10.0152 10.9201C9.84953 10.8224 9.67054 10.8206 9.61531 10.9156C9.5598 11.0108 9.72435 11.0378 9.88974 11.1353C10.0551 11.2328 10.1591 11.3639 10.2143 11.269Z"
          fill="#5F249F"
        />
        <path
          d="M10.0153 10.9201C10.1807 11.0176 10.2699 11.1738 10.2144 11.269C10.1591 11.3639 10.0552 11.2328 9.88979 11.1353C9.7244 11.0379 9.55985 11.0108 9.61536 10.9156C9.67059 10.8207 9.84958 10.8224 10.0153 10.9201Z"
          fill="white"
        />
        <path
          d="M8.11351 6.3193C8.11351 6.3193 8.80965 7.95943 8.11351 8.92514C7.41766 9.89086 5.46547 10.0003 3.9786 12.7624C4.02108 11.8596 4.16722 11.0669 4.22896 10.7631C4.24624 10.6796 4.23094 10.5926 4.18761 10.5199C2.94459 8.42775 3.8115 4.27129 4.63594 3.32496C4.77981 3.15963 5.03074 3.15764 5.17801 3.31954C6.33437 4.58911 7.36866 6.53907 7.36866 6.53907C7.36866 6.53907 7.63856 6.44187 8.11351 6.3193Z"
          fill="#DFCCF3"
        />
        <path
          d="M16.357 6.73856C16.3355 6.73856 16.3134 6.73485 16.2916 6.72744C15.3162 6.38482 14.2672 6.16591 13.1743 6.07612C10.985 5.89569 9.09457 6.27223 8.1628 6.51252C7.70428 6.63081 7.43806 6.72602 7.43551 6.72687C7.33242 6.76393 7.21885 6.70977 7.18203 6.60601C7.14522 6.50226 7.19874 6.38824 7.30183 6.3509C7.31316 6.34691 7.5859 6.24914 8.06424 6.12572C9.02037 5.87916 10.9595 5.49293 13.2065 5.67792C14.3332 5.77028 15.415 5.9966 16.4221 6.35033C16.5255 6.38653 16.5802 6.50055 16.5439 6.60459C16.5153 6.68696 16.4389 6.73856 16.3567 6.73856H16.357Z"
          fill="#5F249F"
        />
        <path
          d="M11.7239 21C11.6151 21 11.5265 20.9116 11.5256 20.8022C11.5248 20.6918 11.6129 20.6018 11.7222 20.6009C12.9205 20.5907 14.1026 20.2574 15.236 19.6107C16.1709 19.0771 17.0673 18.3266 17.8283 17.4401C18.1973 17.0106 18.534 16.5474 18.8022 16.1007C18.9589 15.8407 19.0908 15.5888 19.1951 15.3519C19.342 15.0184 19.4417 14.6017 19.4913 14.1134C19.5513 13.5225 19.5392 12.837 19.4545 12.0759C19.3944 11.5335 19.3134 11.0982 19.2704 10.887C19.2432 10.7551 19.2664 10.618 19.3358 10.5011C20.5347 8.48386 19.6683 4.41263 18.9084 3.54012C18.8767 3.50364 18.8334 3.48312 18.7861 3.48283C18.7855 3.48283 18.785 3.48283 18.7841 3.48283C18.7374 3.48283 18.6938 3.5025 18.6615 3.53813C17.5374 4.77235 16.6022 6.61143 16.5932 6.62996C16.5436 6.72801 16.4241 6.76735 16.3267 6.71747C16.229 6.66758 16.1902 6.5473 16.2397 6.44924C16.2788 6.372 17.208 4.54375 18.3692 3.26877C18.4777 3.14962 18.6312 3.08292 18.7903 3.08406C18.9492 3.08577 19.101 3.15646 19.2064 3.2776C19.6598 3.79809 20.0906 5.14547 20.2537 6.55385C20.369 7.54779 20.4469 9.40853 19.6757 10.7063C19.6573 10.7371 19.6513 10.7724 19.6584 10.8064C19.7029 11.025 19.7864 11.4736 19.8481 12.0318C19.9359 12.821 19.9484 13.5351 19.8855 14.1542C19.8317 14.6843 19.7213 15.1418 19.5576 15.5135C19.4465 15.7658 19.3066 16.0329 19.1415 16.3074C18.858 16.7797 18.5173 17.2486 18.1285 17.7012C17.338 18.6219 16.4057 19.4023 15.432 19.9582C14.2394 20.6388 12.9924 20.9897 11.7259 21.0003H11.7242L11.7239 21Z"
          fill="#5F249F"
        />
        <path
          d="M12.7928 18.348C12.7098 18.348 12.628 18.3395 12.5472 18.3224C11.9856 18.2035 11.7024 17.7101 11.6908 17.689L11.665 17.6434V16.4599C11.665 16.3496 11.7537 16.2604 11.8633 16.2604C11.9729 16.2604 12.0615 16.3496 12.0615 16.4599V17.5319C12.1284 17.6274 12.3241 17.8691 12.6325 17.9327C12.9168 17.9914 13.2372 17.8885 13.5847 17.6271C13.6722 17.561 13.7965 17.5793 13.8622 17.6673C13.9276 17.7557 13.9098 17.8805 13.822 17.9467C13.4671 18.2138 13.1222 18.348 12.7928 18.348Z"
          fill="#5F249F"
        />
        <path
          d="M10.9332 18.348C10.5987 18.348 10.2498 18.1912 9.89291 17.8802C9.81022 17.8081 9.80115 17.6821 9.87281 17.5989C9.94446 17.5157 10.0696 17.5066 10.1523 17.5787C10.4868 17.8703 10.7938 17.9906 11.0649 17.9358C11.4628 17.8557 11.6831 17.4188 11.6851 17.4145C11.7338 17.3162 11.8525 17.2754 11.9505 17.3241C12.0484 17.3726 12.0889 17.4915 12.0411 17.5904C12.0289 17.6154 11.7366 18.2055 11.1464 18.3266C11.0759 18.3412 11.0048 18.3483 10.9329 18.3483L10.9332 18.348Z"
          fill="#5F249F"
        />
        <path
          d="M1.19824 14.6861C1.10789 14.6861 1.02633 14.6237 1.00537 14.5313C0.980731 14.4238 1.04729 14.3167 1.15406 14.2919C1.97792 14.1006 2.93802 13.9832 4.00771 13.943C6.62829 13.8441 9.08912 14.2417 9.11376 14.246C9.22195 14.2637 9.29502 14.3663 9.27746 14.4752C9.2599 14.5838 9.15823 14.6576 9.04976 14.6399C9.02569 14.6359 6.60223 14.2443 4.02244 14.342C2.97767 14.3814 2.0425 14.4954 1.24299 14.6812C1.22798 14.6847 1.21297 14.6864 1.19824 14.6864V14.6861Z"
          fill="#5F249F"
        />
        <path
          d="M2.3623 20.1862C2.30764 20.1862 2.25327 20.1637 2.21418 20.1195C2.1414 20.0371 2.14848 19.9112 2.23033 19.8379C3.11225 19.0472 4.28277 18.2636 5.7096 17.5088C7.54426 16.5383 9.17358 15.9559 9.19001 15.9502C9.2931 15.9135 9.40638 15.9682 9.44292 16.0719C9.47945 16.176 9.42507 16.2897 9.32199 16.3265C9.30584 16.3322 7.70201 16.9057 5.89426 17.8623C4.49603 18.602 3.35213 19.367 2.49428 20.1361C2.45661 20.17 2.40932 20.1865 2.36259 20.1865L2.3623 20.1862Z"
          fill="#5F249F"
        />
        <path
          d="M2.15607 17.7197C2.10792 17.7197 2.05977 17.7023 2.02182 17.667C1.94139 17.5923 1.93629 17.4661 2.01049 17.3851C2.39991 16.9601 3.29288 16.5343 4.74039 16.0833C6.60931 15.5013 8.74615 15.0959 8.7674 15.092C8.87502 15.0714 8.97867 15.143 8.99878 15.2513C9.01889 15.3596 8.94809 15.4639 8.84046 15.4842C8.81922 15.4882 6.70447 15.8895 4.85764 16.4647C3.16402 16.9923 2.53302 17.4036 2.3022 17.6559C2.26312 17.6986 2.20988 17.7203 2.15635 17.7203L2.15607 17.7197Z"
          fill="#5F249F"
        />
        <path
          d="M22.8021 14.6861C22.7874 14.6861 22.7724 14.6844 22.7574 14.681C21.8839 14.478 20.8499 14.3609 19.6839 14.3329C17.218 14.2734 14.9733 14.6362 14.9509 14.6399C14.8427 14.6576 14.741 14.5838 14.7232 14.4752C14.7056 14.3663 14.779 14.264 14.8869 14.246C14.9095 14.2423 17.1888 13.8734 19.6933 13.9339C20.8862 13.9627 21.9468 14.0832 22.8466 14.2922C22.9534 14.317 23.0199 14.4241 22.9953 14.5316C22.974 14.624 22.8925 14.6864 22.8024 14.6864L22.8021 14.6861Z"
          fill="#5F249F"
        />
        <path
          d="M21.6378 20.1862C21.5907 20.1862 21.5437 20.1697 21.5061 20.1358C20.6012 19.3248 19.3839 18.5213 17.888 17.748C16.1717 16.8601 14.6931 16.3316 14.6784 16.3262C14.575 16.2894 14.5209 16.1754 14.5574 16.0717C14.594 15.9676 14.7073 15.9132 14.8103 15.95C14.8254 15.9554 16.3278 16.4921 18.0693 17.3928C19.5949 18.1815 20.8399 19.0042 21.77 19.8376C21.8519 19.9109 21.8589 20.0372 21.7862 20.1192C21.7471 20.1634 21.6927 20.1859 21.638 20.1859L21.6378 20.1862Z"
          fill="#5F249F"
        />
        <path
          d="M21.8439 17.7198C21.7904 17.7198 21.7371 17.6981 21.698 17.6553C21.4536 17.3885 20.7725 16.953 18.9152 16.3946C17.1278 15.8567 15.1793 15.4873 15.1598 15.4836C15.0522 15.4634 14.9814 15.3591 15.0015 15.2507C15.0216 15.1424 15.1249 15.0709 15.2328 15.0914C15.2524 15.0951 17.2207 15.4682 19.0288 16.0121C20.5893 16.4813 21.5856 16.943 21.9897 17.3845C22.0639 17.4655 22.0588 17.5918 21.9781 17.6665C21.9399 17.7018 21.8918 17.7192 21.8439 17.7192V17.7198Z"
          fill="#5F249F"
        />
        <path
          d="M11.7238 21C11.7238 21 11.7227 21 11.7221 21C10.4998 20.9897 9.31199 20.6534 8.1916 20.0004C7.26889 19.4628 6.41387 18.7271 5.65033 17.8135C5.26941 17.358 4.92587 16.8746 4.62878 16.3761C4.43449 16.0497 4.26881 15.7313 4.1357 15.4295C3.98192 15.0803 3.87486 14.655 3.81794 14.1656C3.76866 13.7437 3.7562 13.2686 3.78055 12.7526C3.8236 11.838 3.97257 11.0284 4.0346 10.7229C4.04168 10.6884 4.03573 10.6528 4.0176 10.6223C3.24641 9.32419 3.3243 7.46316 3.43957 6.46922C3.6027 5.06084 4.03346 3.71346 4.48689 3.19297C4.59224 3.0724 4.74376 3.00171 4.90293 3C4.90491 3 4.90689 3 4.90916 3C5.06634 3 5.21729 3.06727 5.32435 3.18471C6.48411 4.45798 7.50084 6.36433 7.54361 6.445C7.59515 6.54219 7.55862 6.66305 7.46204 6.71493C7.36547 6.76681 7.24538 6.73004 7.19384 6.63284C7.18364 6.61345 6.15756 4.69 5.03207 3.45407C4.99922 3.41787 4.95617 3.3982 4.90746 3.39877C4.86045 3.39934 4.81711 3.41958 4.78539 3.45606C4.02553 4.32829 3.15918 8.39923 4.35803 10.4173C4.42741 10.5339 4.45064 10.671 4.42316 10.8035C4.36312 11.0994 4.2184 11.8844 4.17677 12.7715C4.15326 13.2654 4.16516 13.7186 4.21189 14.1188C4.26428 14.5686 4.36057 14.9549 4.4985 15.2676C4.62481 15.5546 4.78313 15.8584 4.9692 16.1708C5.25524 16.6509 5.5866 17.1172 5.95393 17.5564C6.68802 18.4344 7.50764 19.1404 8.3907 19.6546C9.45105 20.2723 10.5731 20.5907 11.7261 20.6004C11.8357 20.6012 11.9235 20.6913 11.9227 20.8016C11.9218 20.9114 11.8332 20.9994 11.7244 20.9994L11.7238 21Z"
          fill="#5F249F"
        />
        <path
          d="M9.58387 12.7766C8.9642 12.7766 8.46008 12.2692 8.46008 11.6455C8.46008 11.0219 8.9642 10.5148 9.58387 10.5148C10.2035 10.5148 10.7077 11.0221 10.7077 11.6455C10.7077 12.2689 10.2035 12.7766 9.58387 12.7766ZM9.58387 10.5433C8.97978 10.5433 8.4884 11.0378 8.4884 11.6455C8.4884 12.2532 8.97978 12.7481 9.58387 12.7481C10.188 12.7481 10.6793 12.2535 10.6793 11.6455C10.6793 11.0375 10.188 10.5433 9.58387 10.5433Z"
          fill="#5F249F"
        />
        <path
          d="M10.1648 11.3164C10.1235 11.3164 10.075 11.2813 10.0107 11.2343C9.97308 11.2069 9.93032 11.1758 9.88274 11.1476C9.83516 11.1197 9.78729 11.0974 9.74538 11.0778C9.66495 11.0402 9.60689 11.0131 9.59329 10.9695C9.58735 10.9506 9.59074 10.9301 9.60349 10.9085C9.63294 10.858 9.6944 10.8306 9.77427 10.8315C9.85215 10.8324 9.94051 10.8594 10.0226 10.9079C10.1048 10.9563 10.1713 11.0205 10.2101 11.0883C10.2503 11.1587 10.2563 11.2257 10.2268 11.2762C10.2144 11.2975 10.1982 11.3107 10.179 11.3146C10.1744 11.3155 10.1696 11.3161 10.1648 11.3161V11.3164ZM9.7703 10.86C9.70176 10.86 9.65135 10.8822 9.62756 10.923C9.61906 10.9375 9.61652 10.9501 9.61991 10.9612C9.62983 10.9928 9.68874 11.0205 9.75699 11.0521C9.79976 11.0721 9.84818 11.0946 9.8969 11.1234C9.94561 11.1522 9.98894 11.1835 10.0272 11.2115C10.0881 11.2556 10.1407 11.2938 10.1727 11.2873C10.1841 11.285 10.1934 11.2767 10.2019 11.2622C10.226 11.2209 10.22 11.1641 10.1852 11.1029C10.1487 11.039 10.0858 10.9786 10.0079 10.9327C9.93003 10.8868 9.84677 10.8609 9.77342 10.86C9.77228 10.86 9.77115 10.86 9.77002 10.86H9.7703Z"
          fill="#5F249F"
        />
        <path
          d="M14.0772 12.7766C13.4575 12.7766 12.9534 12.2692 12.9534 11.6455C12.9534 11.0219 13.4575 10.5148 14.0772 10.5148C14.6968 10.5148 15.2007 11.0221 15.2007 11.6455C15.2007 12.2689 14.6965 12.7766 14.0772 12.7766ZM14.0772 10.5433C13.4731 10.5433 12.9817 11.0378 12.9817 11.6455C12.9817 12.2532 13.4731 12.7481 14.0772 12.7481C14.6813 12.7481 15.1723 12.2535 15.1723 11.6455C15.1723 11.0375 14.681 10.5433 14.0772 10.5433Z"
          fill="#5F249F"
        />
        <path
          d="M14.6741 11.2972C14.6342 11.2972 14.5849 11.2673 14.5203 11.2283C14.4804 11.204 14.4351 11.1767 14.3852 11.1527C14.3354 11.1288 14.2858 11.1105 14.2419 11.0946C14.159 11.0641 14.0989 11.0421 14.0816 10.9997C14.0743 10.9814 14.076 10.9606 14.0867 10.9381C14.1374 10.8315 14.3246 10.8152 14.5045 10.9016C14.5393 10.9184 14.5719 10.9378 14.6008 10.9595C14.7095 11.0396 14.7676 11.1439 14.748 11.2257C14.7455 11.2357 14.7427 11.2442 14.7387 11.2519C14.7282 11.2741 14.7132 11.2884 14.6945 11.2941C14.688 11.2961 14.6812 11.2969 14.6741 11.2969V11.2972ZM14.2768 10.874C14.1983 10.874 14.1363 10.8999 14.1122 10.9506C14.1051 10.9657 14.1037 10.9783 14.108 10.9891C14.1204 11.0196 14.1813 11.0421 14.2518 11.0678C14.2963 11.084 14.3464 11.1026 14.3977 11.1271C14.4487 11.1516 14.4946 11.1795 14.5351 11.204C14.5993 11.2431 14.6549 11.2767 14.6863 11.267C14.6973 11.2636 14.7061 11.2548 14.7132 11.2397C14.7163 11.2337 14.7186 11.2271 14.7206 11.2194C14.7373 11.149 14.6837 11.0561 14.5841 10.9828C14.5563 10.9623 14.5254 10.9438 14.4923 10.9278C14.4175 10.8919 14.3416 10.8742 14.2771 10.8742L14.2768 10.874Z"
          fill="#5F249F"
        />
        <path
          d="M14.485 14.0342C13.7886 14.0342 13.1159 13.8346 12.5396 13.457C11.9573 13.0756 11.5161 12.54 11.2637 11.9083C11.0547 11.3859 11.053 10.8092 11.2589 10.285L13.0018 5.85522L13.3705 6.00202L11.6276 10.4318C11.4591 10.8605 11.4606 11.332 11.6316 11.7593C11.8537 12.3145 12.2422 12.786 12.756 13.1223C13.268 13.4575 13.8659 13.6348 14.4853 13.6348C14.5374 13.6348 14.5906 13.6334 14.6436 13.6308C14.7438 13.626 14.8433 13.6055 14.9395 13.5701L19.6154 11.8539L19.7516 12.2287L19.7199 12.2404L15.0752 13.9449C14.9415 13.994 14.803 14.0222 14.6634 14.0293C14.6039 14.0322 14.5442 14.0339 14.4856 14.0339L14.485 14.0342Z"
          fill="#5F249F"
        />
        <path
          d="M4.15308 12.8575L3.80444 12.6674C4.79427 10.8286 6.00388 10.1229 6.88694 9.60752C7.35962 9.33188 7.73289 9.11383 7.95323 8.80826C8.57432 7.94659 7.93794 6.41307 7.93143 6.39768L8.2962 6.24091C8.32651 6.31217 9.02774 7.99704 8.2744 9.04228C8.00478 9.41654 7.57911 9.66509 7.08603 9.9527C6.19675 10.4718 5.08995 11.1174 4.15336 12.8575H4.15308Z"
          fill="#5F249F"
        />
      </g>
      <defs>
        <clipPath id="clip0_7345_389">
          <rect
            width="22"
            height="18"
            fill="white"
            transform="translate(1 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationCatFacePurple;
