import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineExternalLink: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-external-link"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 9C12.2188 9 12 9.25 12 9.5V14C12 14.5625 11.5312 15 11 15H2C1.4375 15 1 14.5625 1 14V5C1 4.46875 1.4375 4 2 4H5.5C5.75 4 6 3.78125 6 3.5C6 3.25 5.75 3 5.5 3H2C0.875 3 0 3.90625 0 5V14C0 15.125 0.875 16 2 16H11C12.0938 16 13 15.125 13 14V9.5C13 9.25 12.75 9 12.5 9ZM15.5 0H10.5C10.2188 0 10 0.25 10 0.5C10 0.78125 10.2188 1 10.5 1H14.2812L5.625 9.65625C5.4375 9.84375 5.4375 10.1875 5.625 10.375C5.71875 10.4688 5.84375 10.5 6 10.5C6.125 10.5 6.25 10.4688 6.34375 10.375L15 1.71875V5.5C15 5.78125 15.2188 6 15.5 6C15.75 6 16 5.78125 16 5.5V0.5C16 0.25 15.75 0 15.5 0Z"
        fill={color}
        stroke={color}
        strokeWidth="0.25"
      />
    </svg>
  );
};

export default IconOutlineExternalLink;
