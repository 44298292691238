import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineArrowUpFromBracket: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-arrow-up-from-bracket"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5938 8.74489C11.9492 8.74489 12.25 9.04567 12.25 9.40114V11.3699C12.25 12.8464 11.0742 13.9949 9.625 13.9949H2.625C1.14844 13.9949 0 12.8464 0 11.3699V9.40114C0 9.04567 0.273438 8.74489 0.65625 8.74489C1.01172 8.74489 1.3125 9.04567 1.3125 9.40114V11.3699C1.3125 12.1082 1.88672 12.6824 2.625 12.6824H9.625C10.3359 12.6824 10.9375 12.1082 10.9375 11.3699V9.40114C10.9375 9.04567 11.2109 8.74489 11.5938 8.74489ZM5.66016 0.186292L1.94141 3.65895C1.66797 3.93239 1.66797 4.34254 1.91406 4.61598C2.16016 4.88942 2.57031 4.88942 2.84375 4.64332L5.46875 2.18239V8.96364C5.46875 9.34645 5.74219 9.61989 6.125 9.61989C6.48047 9.61989 6.78125 9.34645 6.78125 8.99098V2.18239L9.37891 4.64332C9.65234 4.88942 10.0625 4.88942 10.3086 4.61598C10.418 4.47926 10.5 4.3152 10.5 4.15114C10.5 3.98707 10.418 3.82301 10.2812 3.68629L6.5625 0.213636C6.31641 -0.0598015 5.90625 -0.0598015 5.66016 0.186292Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineArrowUpFromBracket;
