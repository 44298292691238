import IIconType from '../IIconType';

/**
 * IllustrationCatFace
 *
 * @param {number} size - The size of the icon
 * @returns {React.ReactNode} - The SVG element
 */
export const IllustrationShelterPin: React.FC<IIconType> = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.8252 13.3472H19.8359V24.6293H25.8252V13.3472Z"
        fill="#C0D5FA"
      />
      <path
        d="M24.0549 8.89062L25.8252 13.3471H19.8359V9.4925V8.89062H24.0549Z"
        fill="#00205B"
      />
      <path
        d="M19.8358 24.6292V24.6454H16.4429V18.491C16.4429 17.8389 16.1789 17.2489 15.7511 16.8211C15.3233 16.3933 14.7347 16.1307 14.0826 16.1307C12.7786 16.1307 11.7224 17.1869 11.7224 18.491V24.6454H8.4637L8.41797 24.6292V6.35173L11.8138 3.60938H16.44L19.8358 6.35173V24.6292ZM17.3413 9.90248C17.5655 9.51599 17.6039 9.12211 17.2307 8.90379C16.946 8.73709 16.5329 8.91854 16.3087 9.30651C16.0845 9.69301 16.021 10.0898 16.4208 10.3052C16.7099 10.4616 17.1186 10.2905 17.3413 9.90248ZM16.1479 7.98623C16.3057 7.47286 16.1863 6.9359 15.7275 6.84001C15.3277 6.75593 14.8925 7.03769 14.7347 7.55105C14.5768 8.06441 14.6152 8.5704 15.1551 8.69726C15.5519 8.7902 15.9901 8.49959 16.1479 7.98623ZM15.9384 13.3765C16.6657 12.8838 16.3323 11.9249 15.9001 11.2965C15.3513 10.5014 14.5223 9.84495 14.1033 9.84495C13.7463 9.84495 12.8568 10.5029 12.308 11.2965C11.8758 11.9249 11.5424 12.8838 12.2682 13.3765C13.3672 14.1215 14.1033 13.0107 14.1033 13.0107C14.1033 13.0107 14.8409 14.1215 15.9384 13.3765ZM13.2152 8.82118C13.7537 8.69579 13.792 8.18685 13.6342 7.67496C13.4764 7.1616 13.0412 6.87984 12.6414 6.96393C12.1841 7.05981 12.0631 7.59678 12.2225 8.11014C12.3803 8.6235 12.8169 8.91411 13.2152 8.82118ZM11.7873 10.3052C12.187 10.0898 12.1221 9.69301 11.8994 9.30651C11.6752 8.91854 11.2621 8.73709 10.9774 8.90379C10.6027 9.12211 10.6425 9.51599 10.8653 9.90248C11.0895 10.2905 11.4967 10.4616 11.7873 10.3052Z"
        fill="#C0D5FA"
      />
      <path
        d="M17.2302 8.90376C17.6034 9.12209 17.565 9.51596 17.3408 9.90245C17.1181 10.2904 16.7094 10.4615 16.4203 10.3052C16.0205 10.0898 16.084 9.69298 16.3082 9.30648C16.5324 8.91851 16.9455 8.73706 17.2302 8.90376Z"
        fill="#00205B"
      />
      <path
        d="M16.4432 24.3919V24.6456H11.7227V18.4911C11.7227 17.1871 12.7789 16.1309 14.0829 16.1309C14.735 16.1309 15.325 16.3949 15.7514 16.8212C16.1777 17.2476 16.4432 17.8391 16.4432 18.4911V24.3919Z"
        fill="#00205B"
      />
      <path
        d="M15.7274 6.8399C16.1861 6.93579 16.3056 7.47275 16.1478 7.98611C15.99 8.49948 15.5518 8.79008 15.155 8.69715C14.6151 8.57028 14.5767 8.0643 14.7346 7.55094C14.8924 7.03757 15.3276 6.75582 15.7274 6.8399Z"
        fill="#00205B"
      />
      <path
        d="M15.8998 11.2963C16.332 11.9247 16.6654 12.8836 15.9382 13.3763C14.8406 14.1213 14.1031 13.0105 14.1031 13.0105C14.1031 13.0105 13.3669 14.1213 12.2679 13.3763C11.5421 12.8836 11.8755 11.9247 12.3078 11.2963C12.8565 10.5027 13.7461 9.84473 14.1031 9.84473C14.522 9.84473 15.3511 10.5012 15.8998 11.2963Z"
        fill="#00205B"
      />
      <path
        d="M13.6337 7.67496C13.7915 8.18685 13.7532 8.69578 13.2148 8.82117C12.8165 8.91411 12.3798 8.6235 12.222 8.11014C12.0626 7.59677 12.1836 7.05981 12.6409 6.96392C13.0407 6.87984 13.4759 7.1616 13.6337 7.67496Z"
        fill="#00205B"
      />
      <path
        d="M11.8992 9.30648C12.122 9.69298 12.1869 10.0898 11.7871 10.3052C11.4965 10.4615 11.0893 10.2904 10.8651 9.90245C10.6423 9.51596 10.6025 9.12209 10.9772 8.90376C11.2619 8.73706 11.675 8.91851 11.8992 9.30648Z"
        fill="#00205B"
      />
      <path
        d="M8.41793 13.3472H2.42871V24.6293H8.41793V13.3472Z"
        fill="#C0D5FA"
      />
      <path
        d="M8.41793 9.4925V13.3471H2.42871L4.19892 8.89062H8.41793V9.4925Z"
        fill="#00205B"
      />
      <path
        d="M19.8804 25.0882H8.46404C8.21916 25.0882 8.02148 24.8906 8.02148 24.6457C8.02148 24.4008 8.21916 24.2031 8.46404 24.2031H19.8804C20.1253 24.2031 20.323 24.4008 20.323 24.6457C20.323 24.8906 20.1253 25.0882 19.8804 25.0882Z"
        fill="#00205B"
      />
      <path
        d="M8.4178 13.7898H2.42858C2.28254 13.7898 2.14534 13.7176 2.06273 13.5966C1.98012 13.4756 1.9639 13.3207 2.017 13.185L3.78722 8.72852C3.8536 8.56035 4.01734 8.44971 4.19879 8.44971H8.4178C8.66268 8.44971 8.86035 8.64738 8.86035 8.89226C8.86035 9.0088 8.8161 9.11354 8.74234 9.1932C8.8161 9.27286 8.86035 9.37759 8.86035 9.49413V13.3488C8.86035 13.5937 8.66268 13.7913 8.4178 13.7913V13.7898ZM3.08061 12.9047H7.97524V9.49266C7.97524 9.4366 7.98557 9.38202 8.00475 9.33334H4.49973L3.08061 12.9047Z"
        fill="#00205B"
      />
      <path
        d="M8.4181 25.072H2.42888C2.184 25.072 1.98633 24.8744 1.98633 24.6295V13.3473C1.98633 13.1025 2.184 12.9048 2.42888 12.9048C2.67376 12.9048 2.87143 13.1025 2.87143 13.3473V24.1869H7.97555V13.3473C7.97555 13.1025 8.17322 12.9048 8.4181 12.9048C8.66298 12.9048 8.86065 13.1025 8.86065 13.3473V24.6295C8.86065 24.8744 8.66298 25.072 8.4181 25.072Z"
        fill="#00205B"
      />
      <path
        d="M25.8253 13.7899H19.8361C19.5912 13.7899 19.3936 13.5922 19.3936 13.3473V9.49267C19.3936 9.37613 19.4378 9.27139 19.5116 9.19173C19.4378 9.11207 19.3936 9.00733 19.3936 8.8908C19.3936 8.64592 19.5912 8.44824 19.8361 8.44824H24.0551C24.2366 8.44824 24.3988 8.55888 24.4667 8.72705L26.2369 13.1836C26.2915 13.3193 26.2738 13.4742 26.1912 13.5951C26.1086 13.7161 25.9714 13.7884 25.8253 13.7884V13.7899ZM20.2787 12.9048H25.1733L23.7542 9.33335H20.2492C20.2683 9.38203 20.2787 9.43661 20.2787 9.49267V12.9048Z"
        fill="#00205B"
      />
      <path
        d="M25.8253 25.072H19.8361C19.5912 25.072 19.3936 24.8744 19.3936 24.6295V13.3473C19.3936 13.1025 19.5912 12.9048 19.8361 12.9048C20.081 12.9048 20.2787 13.1025 20.2787 13.3473V24.1869H25.3828V13.3473C25.3828 13.1025 25.5804 12.9048 25.8253 12.9048C26.0702 12.9048 26.2679 13.1025 26.2679 13.3473V24.6295C26.2679 24.8744 26.0702 25.072 25.8253 25.072Z"
        fill="#00205B"
      />
      <path
        d="M19.836 9.93511C19.5911 9.93511 19.3935 9.73743 19.3935 9.49255V6.56285L16.2838 4.0521H11.9704L8.86069 6.56285V9.49255C8.86069 9.73743 8.66302 9.93511 8.41814 9.93511C8.17326 9.93511 7.97559 9.73743 7.97559 9.49255V6.3519C7.97559 6.21766 8.03607 6.09227 8.14081 6.00818L11.5367 3.26583C11.6148 3.2024 11.7137 3.16699 11.814 3.16699H16.4402C16.5419 3.16699 16.6393 3.20092 16.7175 3.26583L20.1133 6.00818C20.2181 6.09227 20.2786 6.21913 20.2786 6.3519V9.49255C20.2786 9.73743 20.0809 9.93511 19.836 9.93511Z"
        fill="#00205B"
      />
      <path
        d="M16.4434 24.8346C16.1985 24.8346 16.0008 24.6369 16.0008 24.392V18.4913C16.0008 17.9794 15.8017 17.497 15.4388 17.1341C15.0759 16.7713 14.5965 16.5736 14.0831 16.5736C13.0254 16.5736 12.1654 17.4336 12.1654 18.4913V24.392C12.1654 24.6369 11.9677 24.8346 11.7228 24.8346C11.4779 24.8346 11.2803 24.6369 11.2803 24.392V18.4913C11.2803 16.9453 12.5371 15.6885 14.0831 15.6885C14.8325 15.6885 15.5362 15.9791 16.0643 16.5087C16.5939 17.0383 16.8859 17.7419 16.8859 18.4913V24.392C16.8859 24.6369 16.6883 24.8346 16.4434 24.8346Z"
        fill="#00205B"
      />
      <path
        d="M7.07425 7.88036C6.94444 7.88036 6.81757 7.8243 6.72906 7.71661C6.57564 7.52632 6.60515 7.24751 6.79544 7.09409L8.13933 6.00836C8.32963 5.85494 8.60844 5.88444 8.76186 6.07474C8.91527 6.26504 8.88577 6.54385 8.69547 6.69727L7.35159 7.783C7.26898 7.84938 7.17161 7.88183 7.07425 7.88183V7.88036Z"
        fill="#00205B"
      />
      <path
        d="M21.1803 7.88037C21.083 7.88037 20.9841 7.84791 20.903 7.78153L19.5591 6.6958C19.3688 6.54238 19.3393 6.26357 19.4927 6.07328C19.6462 5.88298 19.925 5.85348 20.1153 6.00689L21.4591 7.09262C21.6494 7.24604 21.6789 7.52485 21.5255 7.71515C21.4385 7.82284 21.3102 7.87889 21.1803 7.87889V7.88037Z"
        fill="#00205B"
      />
      <path
        d="M13.0194 14.0039C12.6978 14.0039 12.3777 13.8962 12.062 13.6823C11.7596 13.4772 11.5663 13.1852 11.5 12.837C11.3642 12.1171 11.8083 11.3736 12.0045 11.0875C12.5311 10.3248 13.5239 9.47656 14.1037 9.47656C14.7218 9.47656 15.657 10.2953 16.2043 11.0875C16.402 11.3736 16.8445 12.1171 16.7088 12.837C16.6439 13.1852 16.4492 13.4772 16.1453 13.6823C15.7381 13.9582 15.3221 14.057 14.9061 13.9759C14.5624 13.908 14.291 13.7295 14.1037 13.5613C13.9163 13.7295 13.6463 13.908 13.3026 13.9759C13.2082 13.995 13.1138 14.0039 13.0194 14.0039ZM14.1037 10.2142C13.9178 10.2304 13.1359 10.7482 12.6122 11.5064C12.3157 11.9372 12.1682 12.3945 12.2258 12.7013C12.2553 12.8577 12.3349 12.9757 12.4765 13.0716C12.7185 13.2353 12.9427 13.2943 13.1595 13.2515C13.5372 13.1778 13.7939 12.8105 13.7968 12.8075C13.8647 12.7043 13.9812 12.6423 14.1037 12.6423C14.2276 12.6423 14.3426 12.7043 14.4105 12.8075C14.9253 13.5628 15.601 13.1601 15.7308 13.0716C15.8724 12.9757 15.9535 12.8577 15.983 12.7013C16.0406 12.3959 15.8916 11.9372 15.5965 11.5064C15.0433 10.7054 14.3161 10.2142 14.1037 10.2142Z"
        fill="#00205B"
      />
      <path
        d="M16.6537 10.7319C16.5121 10.7319 16.3734 10.6994 16.2451 10.6301C15.953 10.4737 15.8394 10.2509 15.7937 10.0931C15.714 9.80987 15.7789 9.48238 15.9884 9.12243C16.1684 8.81265 16.4457 8.58694 16.7525 8.50581C16.9856 8.44385 17.2216 8.47188 17.4164 8.58547C17.8191 8.82002 18.0935 9.33929 17.6598 10.0872C17.4252 10.4943 17.0313 10.7319 16.6522 10.7319H16.6537ZM16.9959 9.21094C16.9768 9.21094 16.9591 9.2139 16.9428 9.21832C16.8528 9.24192 16.7245 9.32601 16.6286 9.49123C16.5106 9.6948 16.487 9.83199 16.5047 9.89248C16.5076 9.90428 16.518 9.93821 16.5962 9.98099C16.6817 10.0267 16.8897 9.94853 17.0225 9.71988C17.2423 9.34076 17.0992 9.25668 17.0446 9.22422C17.0299 9.21537 17.0136 9.21242 16.9959 9.21242V9.21094Z"
        fill="#00205B"
      />
      <path
        d="M11.5558 10.7319C11.1767 10.7319 10.7813 10.4943 10.5468 10.0872C10.1145 9.33781 10.3904 8.82002 10.7931 8.58547C10.9864 8.47188 11.2224 8.44385 11.4555 8.50581C11.7608 8.58694 12.0396 8.81117 12.2196 9.12243C12.4291 9.48533 12.494 9.81282 12.4143 10.0946C12.3701 10.2524 12.255 10.4737 11.9644 10.6301C11.8375 10.6994 11.6974 10.7319 11.5572 10.7319H11.5558ZM11.1855 9.71841C11.3197 9.95148 11.5248 10.0282 11.6133 9.98099C11.6915 9.93968 11.7003 9.90428 11.7033 9.89395C11.721 9.83347 11.6974 9.6948 11.5794 9.49123C11.4835 9.32601 11.3551 9.24192 11.2652 9.21832C11.2357 9.21095 11.1929 9.20504 11.1634 9.22275C11.1029 9.25815 10.9657 9.33781 11.184 9.71841H11.1855Z"
        fill="#00205B"
      />
      <path
        d="M15.309 9.08398C15.2308 9.08398 15.1512 9.07513 15.0715 9.05595C14.7042 8.97039 14.5168 8.75796 14.4239 8.59717C14.2557 8.30361 14.241 7.90383 14.3826 7.44358C14.5906 6.76795 15.1998 6.35342 15.8032 6.48029C16.0687 6.53635 16.2826 6.68829 16.4213 6.92284C16.6057 7.2341 16.6337 7.66191 16.501 8.09561C16.3166 8.69453 15.8297 9.08545 15.309 9.08545V9.08398ZM15.5878 7.19427C15.4049 7.19427 15.1851 7.34474 15.0877 7.65896C15.011 7.90826 15.0022 8.12216 15.0627 8.22985C15.0745 8.2505 15.1069 8.30656 15.2397 8.33754C15.4285 8.38179 15.6896 8.22247 15.7958 7.87728C15.8652 7.65158 15.8622 7.42293 15.787 7.29606C15.7545 7.24148 15.7147 7.21345 15.6527 7.20018C15.6321 7.19575 15.6099 7.19427 15.5878 7.19427Z"
        fill="#00205B"
      />
      <path
        d="M13.0618 9.208C12.5425 9.208 12.0542 8.81708 11.8698 8.21963C11.7356 7.7874 11.7636 7.3596 11.948 7.04834C12.0867 6.81379 12.2991 6.66037 12.5661 6.60431C13.168 6.47745 13.7787 6.89197 13.9867 7.5676C14.1283 8.02933 14.1151 8.42911 13.9454 8.72414C13.8525 8.88641 13.6651 9.09736 13.2993 9.18145C13.2196 9.19915 13.1414 9.20948 13.0618 9.20948V9.208ZM12.7815 7.3183C12.7594 7.3183 12.7372 7.32125 12.7166 7.3242C12.6546 7.33748 12.6148 7.3655 12.5824 7.42009C12.5071 7.54843 12.5042 7.7756 12.5735 7.99983C12.6797 8.34502 12.9393 8.50434 13.1296 8.46009C13.2609 8.42911 13.2919 8.37453 13.3037 8.35387C13.3657 8.24618 13.3568 8.03228 13.2801 7.78298C13.1828 7.46877 12.963 7.3183 12.78 7.3183H12.7815Z"
        fill="#00205B"
      />
    </svg>
  );
};
